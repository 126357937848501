// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/images/profile_second_background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".profile-page-container[data-v-646b221e] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "); /* New background image */\n  background-size: cover; /* Cover the entire container */\n  background-position: center; /* Center the background image */\n}\n.header[data-v-646b221e] {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.header .icon[data-v-646b221e] {\n  font-size: 24px; /* Adjust size as needed */\n}\n.date-and-month[data-v-646b221e] {\n  margin-top: 8px;\n  font-size: 18px;\n  font-weight: normal;\n}\n.year-section h1[data-v-646b221e] {\n  margin-top: 16px;\n  font-size: 48px; /* Large font size for the year */\n  font-weight: bold;\n}\n.description[data-v-646b221e] {\n  text-align: center;\n  margin-top: 8px;\n}\n.location-section[data-v-646b221e] {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-top: 16px;\n}\n.location-arrow .icon[data-v-646b221e] {\n  font-size: 24px; /* Adjust size as needed */\n}\n\n/* Additional styles can be added as needed to match the design */", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
